.default-font-color {
  color: white;
}

.default-bg {
  background: #1a1325;
}

.page-header {
  margin-top: 10vh;
}

.page-gap {
  margin-top: 5vh;
}

.big-page-gap {
  margin-top: 5vmax;
}

.full-row {
  width: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.full-row-left {
  width: auto;
  margin-left: 10vmin;
}

.full-row-tall {
  min-width: auto;
  min-height: 40vh;
  justify-content: center;
}

.secondary-row-header {
  margin: 5vw;
  width: auto;
}

.secondary-row {
  width: auto;
}

.hero-title {
  font-size: 3vmax;
  color: white;
  margin-left: 10vmin;
  margin-right: 10vmin;
}

.hero-explainer {
  font-size: 2vmax;
  color: white;
  margin-left: 10vmin;
  margin-right: 10vmin;
}

.button-set {
  margin: 2vw;
}

.button-set-small {
  margin: 1vw;
}

.lefty {
  justify-content: left;
  align-items: left;
  text-align: left;
}

.centered {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.active-bg {
  background: #321b54;
}

.flex {
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  flex-wrap: wrap;
}

.small-space {
  margin: 1vh;
}

.table-cell-min {
  min-width: 10vw;
}

.footer {
  margin-bottom: 15vh;
}

.link {
  color: #cda8f0;
}

.card-margin {
  margin: 2vmin;
}

.min-height {
  min-height: 100vh;
}

.header {
  color: white;
  font-size: 2vmax;
}

.header-secondary {
  color: #854eca;
  font-size: 1.5vmin;
}

.ant-layout-sider {
  background: #1a1325 !important;
}

.paragraph {
  font-size: 1vmax;
}
