.how-primary {
  padding-bottom: 10vh;
}

.how-secondary {
  background: #24163a;
  padding-bottom: 10vh;
}

.how-explainer {
  font-size: 2vmax;
  textalign: center;
  color: white;
  margin-left: 20vmin;
  margin-right: 20vmin;
}

.how-card {
  width: 100%;
  height: 100%;
  padding: 3vmin;
  margin: 2vmin;
  background: #1a1325;
}
