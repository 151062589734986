.quill {
  width: 90vw;
  height: 50vh;
}

.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #fff !important;
}

.ql-toolbar .ql-fill {
  fill: #fff !important;
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: #fff !important;
}

button:hover .ql-stroke,
.ql-picker-label:hover .ql-stroke {
  fill: none;
  stroke: #cda8f0 !important;
}

.ql-active .ql-stroke {
  fill: none;
  stroke: #cda8f0 !important;
}

button:hover .ql-fill,
.ql-picker-label:hover .ql-fill {
  fill: #cda8f0 !important;
  stroke: none;
}

.ql-picker-label {
  color: #fff !important;
}

.ql-active .ql-fill {
  fill: #cda8f0 !important;
  stroke: none;
}

.ql-picker-item {
  background: #1a1325 !important;
}

.ql-picker-item:hover {
  color: #cda8f0 !important;
}

.ql-picker-options {
  background: #1a1325 !important;
}
